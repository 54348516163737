import { Container } from "react-bootstrap";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lightgallery-bundle.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import "./famDish.scss";
import { Helmet } from "react-helmet";
import WhatsappIcon from "../components/whatsappIcon";
const FamDish = () => {
  return (
    <>
      <WhatsappIcon />
      <Helmet>
        <title>「招牌菜」介紹 - 食得妙</title>
        {/* <meta
          name="description"
          content="食得妙自 1995 年開業至今，一直堅持無味精，以高級食材製作出懷舊菜，有多種招牌菜式任君選擇"
        /> */}
        <meta property="og:title" content="招牌菜式 - 食得妙" />
        {/* <meta
          property="og:description"
          content="食得妙自 1995 年開業至今，一直堅持無味精，以高級食材製作出懷舊菜，有多種招牌菜式任君選擇"
        /> */}
      </Helmet>
      <section id="famDishesSection" className="">
        <Container>
          <div className="sectionTitleWrapper">
            <div className="leftDec">
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
            </div>
            <h2 className="basicCol">招牌菜式</h2>
            <div className="rightDec">
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
            </div>
          </div>

          <LightGallery
            elementClassNames="gallery-group"
            download={false}
            selector="a"
            plugins={[lgThumbnail]}
            showCloseIcon={true}
          >
            <div className="controlDivSize">
              <a
                className="galleryWrapper"
                href={require("../assets/image/貴妃醬炒蟶子皇.jpg")}
                data-html="<h4>貴妃醬炒蟶子皇煎米粉底</h4>"
              >
                <div className="gallerImage">
                  <img
                    alt="貴妃醬炒蟶子皇煎米粉底"
                    src={require("../assets/image/貴妃醬炒蟶子皇.jpg")}
                  />
                </div>

                <div className="famdishWord">
                  <div className="basicCol famDishName">貴妃醬炒蟶子皇</div>
                  <div className="forCol ContentWordSize">
                    老板大廚明哥，秘製貴妃醬蟶子皇調製肉味爽甜軟口
                  </div>
                </div>
              </a>
            </div>

            {/* <div className="controlDivSize">
              <a
                className="galleryWrapper"
                href={require("../assets/image/famdish1.jpg")}
                data-html="<h4>避風塘百花煎釀三寶</h4>"
              >
                <div className="gallerImage">
                  <img
                    alt="避風塘百花煎釀三寶"
                    src={require("../assets/image/famdish1.jpg")}
                  />
                </div>

                <div className="famdishWord">
                  <div className="basicCol famDishName">避風塘百花煎釀三寶</div>
                  <div className="forCol ContentWordSize">
                    招牌手打蝦膠釀三寶，蝦膠真材實料有彈牙口感。
                  </div>
                </div>
              </a>
            </div> */}

            <div className="controlDivSize">
              <a
                className="galleryWrapper"
                href={require("../assets/image/famish2.jpg")}
                data-html="<h4>鮮鳳梨咕嚕肉</h4>"
              >
                <div className="gallerImage">
                  <img
                    alt="鮮鳳梨咕嚕肉"
                    src={require("../assets/image/famish2.jpg")}
                  />
                </div>

                <div className="famdishWord">
                  <div className="basicCol famDishName">鮮鳳梨咕嚕肉</div>
                  <div className="forCol ContentWordSize">
                    採用新鮮梅頭肉，肉質外脆肉嫩，汁帶鮮鳳梨微酸甜汁味，食落開胃開心
                  </div>
                </div>
              </a>
            </div>

            <div className="controlDivSize">
              <a
                className="galleryWrapper"
                href={require("../assets/image/famdish3.jpg")}
                data-html="<h2>大澳脆香蝦醬鮮雞翼</h2>"
              >
                <div className="gallerImage">
                  <img
                    alt="大澳脆香蝦醬鮮雞翼"
                    src={require("../assets/image/famdish3.jpg")}
                  />
                </div>

                <div className="famdishWord">
                  <div className="basicCol famDishName">大澳脆香蝦醬鮮雞翼</div>
                  <div className="forCol ContentWordSize">
                    新鮮雞翼，肉質外脆內軟，肉味帶小蝦醬香味好Juicy
                  </div>
                </div>
              </a>
            </div>
          </LightGallery>
        </Container>
      </section>
    </>
  );
};
export default FamDish;
