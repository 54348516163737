import { Col, Container, Row } from "react-bootstrap";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lightgallery-bundle.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import $ from "jquery";
import LightGallery from "lightgallery/react";
import "./menuDetail.scss";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import WhatsappIcon from "../components/whatsappIcon";
const MenuDetail = () => {
  const filterRef = useRef(null);
  const [prevDivContent, setPrevDivContent] = useState("");
  let a;
  let b;
  let c;
  let d;
  let e;
  let f;
  let g;
  let j;
  let navChoice;
  let sectionHeight;
  let currentElement;
  let current;
  window.addEventListener("scroll", () => {
    e = document.querySelectorAll("div.menuTypeRow");
    e.forEach((div) => {
      j = div.offsetTop;
      const d = window.pageYOffset;
      f = div.getAttribute("id");
      sectionHeight = div.clientHeight;
      navChoice = document.querySelectorAll(".navChoice");
      // g = currentElement.querySelector(`a[property="#${f}"]`);
      let present = j - sectionHeight / 3;
      if (d >= present) {
        current = div.getAttribute("id");
        // console.log(current)
        // activeElement = g;
      }
    });

    navChoice.forEach((element) => {
      element.classList.remove("active");
      if (element.getAttribute("property") === current) {
        element.classList.add("active");
      }
    });
  });
  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        const headerRect = document
          .querySelector("header")
          .getBoundingClientRect();
        const filterRect = filterRef.current.getBoundingClientRect();
        const c = filterRect.top - headerRect.height;
        const d = window.pageYOffset;
        if (d >= c) {
          $("#filterMenu").addClass("srcollEffect");
        } else {
          $("#filterMenu").removeClass("srcollEffect");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleScroll = () => {
  //   a = $("header").height();
  //   b = filterRef.current.offsetTop;
  //   c = b - a;
  //   d = window.pageYOffset;
  //   if (d >= c) {
  //     $("#filterMenu").addClass("srcollEffect");
  //   } else {
  //     $("#filterMenu").removeClass("srcollEffect");
  //   }
  // };
  // currentElement = filterRef.current;

  //   if (activeElement) {
  //     if (activeElement !== prevDivContent) {
  //       if (prevDivContent) {
  //         const activeElements = document.querySelectorAll(".active");
  //         activeElements.forEach((element) => {
  //           element.classList.remove("active");
  //         });
  //       }
  //       activeElement.classList.add("active");
  //       setPrevDivContent(activeElement);
  //     }
  //   }

  return (
    <>
      <WhatsappIcon />
      <Helmet>
        <title>餐牌菜式介紹 - 食得妙</title>
        {/* <meta
          name="description"
          content="食得妙自 1995 年開業至今，一直堅持無味精，以高級食材製作出懷舊菜，適合家庭、朋友及商務聚餐，歡迎瀏覽以下菜單。"
        /> */}
        <meta property="og:title" content="餐牌菜式介紹 - 食得妙" />
        {/* <meta
          property="og:description"
          content="食得妙自 1995 年開業至今，一直堅持無味精，以高級食材製作出懷舊菜，適合家庭、朋友及商務聚餐，歡迎瀏覽以下菜單。"
        /> */}
      </Helmet>
      <div id="DishIntro" className="sectionAfterHeader">
        <div className="sectionTitleWrapper">
          <div className="leftDec">
            <div>
              <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
            </div>
            <div>
              <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
            </div>
          </div>
          <h2 className="basicCol">餐牌菜式介紹</h2>
          <div className="rightDec">
            <div>
              <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
            </div>
            <div>
              <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
            </div>
          </div>
        </div>
        <div id="filterMenu" ref={filterRef} className="menuFilter">
          <div className="scrollable">
            <div className="optionWrapper">
              <a
                className="navChoice"
                property="setMen"
                role="button"
                onClick={() =>
                  document
                    .getElementById("setMen")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                套餐
              </a>
              <a
                className="navChoice"
                property="Classic"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Classic")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                大廚明哥懷舊菜
              </a>
              <a
                className="navChoice"
                property="rice"
                role="button"
                onClick={() =>
                  document
                    .getElementById("rice")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                粉麵飯
              </a>
              <a
                className="navChoice"
                property="sideDish"
                role="button"
                onClick={() =>
                  document
                    .getElementById("sideDish")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                前菜
              </a>
              <a
                className="navChoice"
                property="soup"
                role="button"
                onClick={() =>
                  document
                    .getElementById("soup")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                湯羹
              </a>
              <a
                className="navChoice"
                property="Abalone"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Abalone")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                鮑參翅肚
              </a>
              <a
                className="navChoice"
                property="Crab"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Crab")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                蟹蝦
              </a>
              <a
                className="navChoice"
                property="Shrimp"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Shrimp")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                鎮店蝦膠
              </a>
              <a
                className="navChoice"
                property="Oyster"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Oyster")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                蟶子、桶蠔
              </a>
              <a
                className="navChoice"
                property="Seafood"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Seafood")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                海產
              </a>
              <a
                className="navChoice"
                property="Fish"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Fish")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                魚類
              </a>
              <a
                className="navChoice"
                property="Pork"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Pork")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                豬肉
              </a>
              <a
                className="navChoice"
                property="Chicken"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Chicken")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                雞肉
              </a>
              <a
                className="navChoice"
                property="Vegelable"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Vegelable")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                時蔬
              </a>
              <a
                className="navChoice"
                property="Bean"
                role="button"
                onClick={() =>
                  document
                    .getElementById("Bean")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                豆腐、蛋類
              </a>
            </div>
          </div>
        </div>

        <section id="MenuSection" className="">
          <Container fluid>
            <LightGallery
              selector="a"
              speed={500}
              plugins={[lgThumbnail]}
              download={false}
            >
              <div id="setMen" className="menuTypeRow">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-src={require("../assets/image/IMG_7643.jpg")}
                      data-sub-html="柑桔蜜煎蝦餅 4件"
                      title="柑桔蜜煎蝦餅 4件"
                    >
                      <div className="filterImgBg">
                        <div>柑桔蜜煎蝦餅 4件</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/IMG_7643.jpg")}
                        alt="柑桔蜜煎蝦餅 4件"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0 ">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">4人套餐</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">私房燉靚湯</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                柑桔蜜煎蝦餅 4件
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                清蒸游水海斑
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                貴妃醬炒蟶子皇（煎米粉底）
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                黑蒜蒸新鮮龍崗雞（半隻）
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蒜茸炒西蘭花苗
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蛋白生磨杏仁茶湯圓
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                        <div className="setPriceWrapper">
                          <div className="setPrice">套餐價格$2380</div>
                          <div className="changeSetPrice">
                            轉蟹肉雞絲生翅加$350
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>
              <div id="setMen" className="menuTypeRow">
                <Row>
                  <Col lg={7} md={12} className="p-0 order-lg-1 order-2 ">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">6人套餐</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">私房燉靚湯</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                貴妃醬炒蟶子皇
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                上湯焗龍蝦（伊麵底）
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                清蒸游水海斑
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮鳳梨咕嚕肉
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                秘製沙薑雞 半隻
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮百合銀杏浸時蔬
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蛋白生磨杏仁茶湯圓
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                        <div className="setPriceWrapper">
                          <div className="setPrice">套餐價格$3588</div>
                          <div className="changeSetPrice">
                            轉蟹肉雞絲生翅加$280
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={12}
                    role="group"
                    className="imageContainer order-1 order-lg-2"
                  >
                    <a
                      href=""
                      className="lightgallery-item"
                      data-src={require("../assets/image/12333.jpg")}
                      data-sub-html="鮮鳳梨咕嚕肉"
                      title="鮮鳳梨咕嚕肉"
                    >
                      <div className="filterImgBg ">
                        <div className="">鮮鳳梨咕嚕肉</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/12333.jpg")}
                        alt="鮮鳳梨咕嚕肉"
                      />
                    </a>
                  </Col>
                </Row>
              </div>
              <div id="setMen" className="menuTypeRow">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-src={require("../assets/image/famdish3.jpg")}
                      data-sub-html="大澳脆香鮮雞翼"
                      title="大澳脆香鮮雞翼"
                    >
                      <div className="filterImgBg">
                        <div>大澳脆香鮮雞翼</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/famdish3.jpg")}
                        alt="大澳脆香鮮雞翼"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0 ">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">珍饌十二人宴</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">私房燉靚湯</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">鹽燒秋葵</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">陳醋雲耳</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                大澳脆香鮮雞翼
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                黃金焗海中蝦
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                貴妃醬炒蟶子皇（煎米粉底）
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮鳳梨咕嚕肉
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                清蒸游水海斑
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮拆蟹肉扒時蔬
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">薑蔥霸王雞</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                瑤柱菜粒帶子粒炒飯
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蛋白杏仁茶或薑茶湯圓
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                        <div className="setPriceWrapper">
                          <div className="setPrice">套餐價格$6,688</div>
                          <div className="changeSetPrice">另加一服務費</div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>

              <div id="setMen" className="menuTypeRow">
                <Row>
                  <Col lg={7} md={12} className="p-0 order-lg-1 order-2 ">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">盛饌十二人宴</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">私房燉靚湯</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">鹽燒秋葵</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                西芹海蜇小炒皇
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                凍食南非鮮鮑魚
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                貴妃醬炒蟶子皇
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                芝士牛油焗中蝦（煎米粉底）
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">陳皮無錫骨</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                清蒸游水海斑{" "}
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮拆蟹肉扒時蔬
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">秘制沙薑雞</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                瑤柱菜粒帶子粒炒飯
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蛋白杏仁茶或薑茶湯圓
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                        <div className="setPriceWrapper">
                          <div className="setPrice">套餐價格$7,888</div>
                          <div className="changeSetPrice">另加一服務費</div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={12}
                    role="group"
                    className="imageContainer order-1 order-lg-2"
                  >
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/貴妃醬炒蟶子皇.jpg")}
                      data-sub-html="貴妃醬炒蟶子皇"
                      title="貴妃醬炒蟶子皇"
                    >
                      <div className="filterImgBg ">
                        <div className="">貴妃醬炒蟶子皇</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/貴妃醬炒蟶子皇.jpg")}
                        alt="貴妃醬炒蟶子皇"
                      />
                    </a>
                  </Col>
                </Row>
              </div>

              <div id="setMen" className="menuTypeRow">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-src={require("../assets/image/貴妃醬炒蟶子皇.jpg")}
                      data-sub-html="貴妃醬炒蟶子皇"
                      title="貴妃醬炒蟶子皇"
                    >
                      <div className="filterImgBg">
                        <div>
                          極饌十二人宴
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/貴妃醬炒蟶子皇.jpg")}
                        alt="貴妃醬炒蟶子皇"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0 ">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">極饌十二人宴</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮拆蟹肉雞絲生翅
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                西芹腰果炒帶子
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                豉油皇煎海中蝦
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                貴妃醬炒蟶子皇（煎米粉底 ）
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                清蒸游水海斑
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮鳳梨咕嚕肉
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮑汁柚皮鵝掌生菜煲
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮百合銀杏浸時蔬
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                脆皮蝦醬燒雞
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">金蒜蒸蟹飯</div>
                              <div className="DishTitleEng"></div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蛋白杏仁茶或薑茶湯圓
                              </div>
                              <div className="DishTitleEng"></div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                        <div className="setPriceWrapper">
                          <div className="setPrice">套餐價格$9,288</div>
                          <div className="changeSetPrice">另加一服務費</div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>

              <div id="Classic" className="menuTypeRow">
                <Row>
                  <Col lg={7} md={12} className="p-0 order-lg-1 order-2">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">大廚明哥 懷舊菜</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮑汁蝦籽扒柚皮(兩件)
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Braised Pomelo Peel with Shrimp-Roc{" "}
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮑汁鵝掌生菜煲（鵝掌四隻）
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Braised Goose's Web & Lettuce with Abalone Sauce
                                in Casserole
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮑汁柚皮生菜煲
                              </div>
                              <div className="DishTitleEng">
                                Braised Pomelo Peel & Lettuce with Abalone Sauce
                                in Casserole
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                海參鵝掌海皇煲
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Braised Sea Cucumber / Goose's Web & Seafood in
                                Casserole
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">瑤柱炒桂花</div>
                              <div className="DishTitleEng">
                                Stir-fried Conpoy with Egg-white
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="subTitle PSRed">
                              歡迎兩天前預訂
                              <br />
                              Welcome to Reserve
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                濃湯肘子雞燉翅
                              </div>
                              <div className="DishTitleEng">
                                Double-boiled Shark's Fin with Yunnan Ham &
                                Chicken in Supreme Soup
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                海鹽玫瑰焗鮮雞
                              </div>
                              <div className="DishTitleEng">
                                Chicken in Sea Salt with Mei Kuei Lu Wine
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                濃湯雲吞雞煲
                              </div>
                              <div className="DishTitleEng">
                                Braised Chicken with Dumping in Casserole
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                富甲一方八寶鴨
                                （全鴨，糯米，薏米，栗子，蓮子，百合，花菇，瑤柱，火腿）
                              </div>
                              <div className="DishTitleEng">
                                Braised Whole Duck Stuffed with Glutinous Rice,
                                Barley, Chestnuts. Lotus, Lily, Mushroom, Conpoy
                                and Jin-Hua Ham
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮑魚寶貝鮮雞（全雞，南非或吉品鮑魚，花菇）
                              </div>
                              <div className="DishTitleEng">
                                Braised Whole Chicken Stuffed with Abalone &
                                Mushroom
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={12}
                    role="group"
                    className="imageContainer order-1 order-lg-2"
                  >
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu14.jpg")}
                      data-sub-html="鮑汁蝦籽扒柚皮（兩件）、鮑汁鵝掌生菜煲（鵝掌四隻）、海鹽玫瑰焗鮮雞（歡迎預訂）"
                      title="大廚明哥
                      懷舊菜"
                    >
                      <div className="filterImgBg ">
                        <div className="">
                          鮑汁蝦籽扒柚皮（兩件）
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                        <div>｜</div>
                        <div className="">
                          鮑汁鵝掌生菜煲（鵝掌四隻）
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                        <div>｜</div>
                        <div className="">
                          海鹽玫瑰焗鮮雞（歡迎預訂）
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu14.jpg")}
                        alt="鮮鳳梨咕嚕肉（無骨）"
                      />
                    </a>
                  </Col>
                </Row>
              </div>

              <div id="rice" className="menuTypeRow">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-src={require("../assets/image/menu1.jpg")}
                      data-sub-html="老婆仔炒飯"
                      title="粉麵飯"
                    >
                      <div className="filterImgBg">
                        <div>
                          老婆仔炒飯
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu1.jpg")}
                        alt="老婆仔炒飯"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0 ">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">粉麵飯</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                老婆仔炒飯（瑤柱，翠瓜粒，帶子粒，蛋白)
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Fried Rice with Scallop, Vegetable & Egg White
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                大富貴炒飯（瑤柱，蛋白，蔥花）
                              </div>
                              <div className="DishTitleEng">
                                Fried Rice with Conpoy, Egg White & Scallion
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                大肚婆炒飯（瑤柱，薑粒，蛋白）
                              </div>
                              <div className="DishTitleEng">
                                Fried Rice with Conpoy, Ginger & Egg White
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鹹蛋黃蝦仁炒飯
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Fried Rice with Shrimp & Egg Yolk
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                頭抽銀芽炒麵
                              </div>
                              <div className="DishTitleEng">
                                Fried Noodle with Bean Sprouts in Soya Sauce
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                XO醬銀芽炒麵
                              </div>
                              <div className="DishTitleEng">
                                Fried Noodle with Bean Sprouts in XO Spicy Sauce
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                銀芽肉絲炒麵
                              </div>
                              <div className="DishTitleEng">
                                Fried Noodle with Bean Sprouts & Shredded Pork
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                雲腿絲上湯米粉/麵
                              </div>
                              <div className="DishTitleEng">
                                Noodle / Vermicelli in Soup with Shredded Ham
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>

              <div id="sideDish" className="menuTypeRow">
                <Row>
                  <Col lg={7} md={12} className="p-0 order-lg-1 order-2 ">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">前菜</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                南非一口鮮鮑魚（冷盤)
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Chilled Fresh Abalone with Wasabi Dressing
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                陳醋雲耳（冷盤）
                              </div>
                              <div className="DishTitleEng">
                                Marinated Fungus in Black Vinegar
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                海蜇風味菜（冷盤）
                              </div>
                              <div className="DishTitleEng">
                                Stirred Jelly-fish with Dried Shrimp & Cucumber
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鹽燒四季豆
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Fried String Bean with Spiced Salt
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鹽燒秋葵{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Fried Okra with Spiced Salt
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                椒鹽海皇粒
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Assorted Seafood with Bean Curd in
                                Salt & Pepper
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">沙薑雞腳</div>
                              <div className="DishTitleEng">
                                Fried Noodle with Bean Sprouts & Shredded Pork
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                雲腿絲上湯米粉/麵
                              </div>
                              <div className="DishTitleEng">
                                Noodle / Vermicelli in Soup with Shredded Ham
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={12}
                    role="group"
                    className="imageContainer order-1 order-lg-2"
                  >
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu2.jpg")}
                      data-sub-html="南非一口鮮鮑魚（冷盤）、鹽燒秋葵、椒鹽海皇粒"
                      title="前菜"
                    >
                      <div className="filterImgBg ">
                        <div className="">
                          南非一口鮮鮑魚（冷盤）
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                        <div className="">｜</div>
                        <div className="">
                          鹽燒秋葵 <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                        <div className="">｜</div>
                        <div className="">
                          椒鹽海皇粒 <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu2.jpg")}
                        alt="前菜"
                      />
                    </a>
                  </Col>
                </Row>
              </div>

              <div id="soup" className="menuTypeRow ">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu3.jpg")}
                      data-sub-html="私房燉湯（材料新鮮、無味精）"
                      title="湯羹"
                    >
                      <div className="filterImgBg withRecIcon">
                        <span>私房燉湯（材料新鮮、無味精）</span>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu3.jpg")}
                        alt="私房燉湯（材料新鮮、無味精）"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">湯羹</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                約四位用 （歡迎預訂）
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                私房燉湯（材料新鮮、無味精）
                              </div>
                              <div className="DishTitleEng">
                                Slow-cooked Soup
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">瑤柱粟米羹</div>
                              <div className="DishTitleEng">
                                Sweet Corn Soup with Conpoy
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蟹籽豆腐海皇羹
                              </div>
                              <div className="DishTitleEng">
                                Assorted Seafood Soup with Bean Curd
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                香茜皮蛋/時菜豆腐魚雲湯
                              </div>
                              <div className="DishTitleEng">
                                Fish-head Soup with Preserved Egg & Coriander /
                                Bean Curd & Vegetable
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>

              <div id="Abalone" className="menuTypeRow">
                <Row>
                  <Col lg={7} md={12} className="p-0 order-lg-1 order-2">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">鮑參翅肚</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮑汁厚花膠扣鮑魚 (歡迎預訂)
                              </div>
                              <div className="DishTitleEng">
                                Braised Abalone with Fish Maw (Welcome to
                                Reserve)
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮑汁遼參扣鮑魚
                              </div>
                              <div className="DishTitleEng">
                                Braised Abalone with Sea Cucumber
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮑汁鵝掌扣鮑魚
                              </div>
                              <div className="DishTitleEng">
                                Braised Abalone with Goose's Web
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮑汁鵝掌扣遼參
                              </div>
                              <div className="DishTitleEng">
                                Braised Sea Cucumber with Goose's Web
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                金牌魚翅撈飯
                              </div>
                              <div className="DishTitleEng">
                                Braised Superior Shark's Fin in Brown Sauce with
                                Fried Rice
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">紅燒大鮑翅</div>
                              <div className="DishTitleEng">
                                Braised Superior Shark's Fin in Brown Sauce
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                紅燒蟹肉生翅
                              </div>
                              <div className="DishTitleEng">
                                Braised Shark's Fin with Crab Meat in Brown
                                Sauce
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                原盅濃湯雞燉翅（供二至四佐用）
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Double-boiled Shark's Fin Soup with Chicken (For
                                2 - 4 persons)
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div className="PS">*備中東及吉品鮑魚供選配</div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={12}
                    role="group"
                    className="imageContainer order-1 order-lg-2"
                  >
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu4.jpg")}
                      data-sub-html="鮑汁鵝掌扣鮑魚、金牌魚翅撈飯"
                      title="鮑參翅肚"
                    >
                      <div className="filterImgBg ">
                        <div className="">鮑汁鵝掌扣鮑魚</div>
                        <div className="">｜</div>
                        <div className="">金牌魚翅撈飯</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu4.jpg")}
                        alt="鮑汁鵝掌扣鮑魚"
                      />
                    </a>
                  </Col>
                </Row>
              </div>

              <div id="Crab" className="menuTypeRow">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu5.jpg")}
                      data-sub-html="金蒜蒸蟹飯、胡椒粉絲蟹煲、芝士牛油焗蝦（煎米粉底）"
                      title="蟹蝦"
                    >
                      <div className="filterImgBg ">
                        <div>
                          金蒜蒸蟹飯
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                        <div className="">｜</div>
                        <div className="">
                          胡椒粉絲蟹煲 <span className="bgWithReIcon"></span>
                        </div>
                        <div className="">｜</div>
                        <div className="">
                          芝士牛油焗蝦（煎米粉底）{" "}
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu5.jpg")}
                        alt="金蒜蒸蟹飯"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">蟹蝦</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                花雕蛋白蒸蟹
                              </div>
                              <div className="DishTitleEng">
                                Steamed Crab with Yellow Wine & Egg White
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                避風塘炒蟹{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Fried Crab with Crispy Garlic
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                四川香辣炒蟹{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Fried Crab with Chili in Sichuan Style
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">薑蔥炒蟹</div>
                              <div className="DishTitleEng">
                                Fried Crab with Ginger & Spring Onion
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                黃金焗中蝦*
                              </div>
                              <div className="DishTitleEng">
                                Fried Prawn with Salted Egg Yolk
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                豉油皇焗中蝦*
                              </div>
                              <div className="DishTitleEng">
                                Fried Prawn with Soya Sauce
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                油鹽焗奄仔蟹（需時約30分鐘）
                              </div>
                              <div className="DishTitleEng">
                                Baked Crab with Salt (Preparation Takes 30
                                Minutes)
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                金蒜蒸蟹飯{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Steamed Crab with Rice and Garlic
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                胡椒粉絲蟹煲
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Baked Crab with Pepper & Vermicelli
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                芝士牛油焗中蝦*（煎米粉底）
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Baked Prawn in Butter Cheese with Pan-fried
                                Vermicelli
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                胡椒粉絲中蝦煲*
                              </div>
                              <div className="DishTitleEng">
                                Baked Prawn with Pepper & Vermicelli
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese PSRed">
                                * 轉花竹蝦
                              </div>
                              <div className="DishTitleEng PSRed">
                                Upgrade to Kuruma Prawn
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>

              <div id="Shrimp" className="menuTypeRow">
                <Row>
                  <Col lg={7} md={12} className="p-0 order-lg-1 order-2">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">鎮店蝦膠</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                招牌爆槳蝦丸
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Minced Shrimp Balls Stuffed with
                                Creamy Cheese
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">酥炸蝦丸</div>
                              <div className="DishTitleEng">
                                Deep-fried Minced Shrimp Balls
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                小棠菜煎蝦餅
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Vegetable Stuffed with Minced Shrimp
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                柑桔蜜煎蝦餅
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Minced Shrimp with Tangerine Sauce
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                豉油皇/豉汁百花煎釀三寶
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Eggplant, Pepper & Chili Stuffed with
                                Minced Shrimp in Soya / Black Bean Sauce
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                避風塘百花煎釀三寶
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Eggplant, Pepper & Chili Stuffed with
                                Minced Shrimp in Crispy Garlic
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={12}
                    role="group"
                    className="imageContainer order-1 order-lg-2"
                  >
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu6.jpg")}
                      data-sub-html="避風塘百花煎釀三寶、柑桔蜜煎蝦餅"
                      title="鎮店蝦膠"
                    >
                      <div className="filterImgBg ">
                        <div className="">避風塘百花煎釀三寶</div>
                        <div className="">｜</div>
                        <div className="">柑桔蜜煎蝦餅</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu6.jpg")}
                        alt="避風塘百花煎釀三寶"
                      />
                    </a>
                  </Col>
                </Row>
              </div>

              <div id="Oyster" className="menuTypeRow">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu7.jpg")}
                      data-sub-html="招牌秘製貴妃醬炒蟶子皇、薑蔥/砵酒焗桶蠔"
                      title="蟶子 • 桶蠔"
                    >
                      <div className="filterImgBg ">
                        <div>
                          招牌秘製貴妃醬炒蟶子皇
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>{" "}
                        <div className="">｜</div>
                        <div className="">薑蔥/砵酒焗桶蠔</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu7.jpg")}
                        alt="招牌秘製貴妃醬炒蟶子皇"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">蟶子 • 桶蠔</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                招牌秘製貴妃醬炒蟶子皇{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Razor Shell Fried with Spicy Sauce
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蒜蓉粉絲蒸蟶子
                              </div>
                              <div className="DishTitleEng">
                                Razor Shell Steamed with Garlic
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                四川香辣炒蟶子{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Razor Shell Fried with Chili in Sichuan Style
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                薑蔥/砵酒焗桶蠔煲
                              </div>
                              <div className="DishTitleEng">
                                Baked Oyster with Ginger & Spring Onion or Port
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                避風塘/酥炸桶蠔
                              </div>
                              <div className="DishTitleEng">
                                Fried with Crispy Garlic / Deep-fried Oyster
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>

              <div id="Seafood" className="menuTypeRow">
                <Row>
                  <Col lg={7} md={12} className="p-0 order-lg-1 order-2">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">海產</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                金沙翠瓜/涼瓜帶子
                              </div>
                              <div className="DishTitleEng">
                                Stir-fried Scallop with Chinese Zucchini /
                                Bitter Melon & Egg Yolk
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                柑桔蜜明蝦球
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Crispy Shrimp with Tangerine Sauce
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">咕嚕蝦球</div>
                              <div className="DishTitleEng">
                                Sweet & Sour Prawn
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">滑蛋鮮蝦仁</div>
                              <div className="DishTitleEng">
                                Shrimp with Scrambled Egg
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">椒鹽焗鮮魷</div>
                              <div className="DishTitleEng">
                                Deep-fried Cuttlefish with Salt & Pepper
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                翠玉瓜腰果炒帶子
                              </div>
                              <div className="DishTitleEng">
                                Stir-Fried Scallop with Chinese Zucchini
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                芝士鮮魷西蘭花
                              </div>
                              <div className="DishTitleEng">
                                Braised Cuttlefish with Broccoli & Cheese
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                海鮮豆腐煲
                                <span>
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Assorted Seafood with Bean Curd In Casserole
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={12}
                    role="group"
                    className="imageContainer order-1 order-lg-2"
                  >
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu8.jpg")}
                      data-sub-html="翠玉瓜腰果炒帶子"
                      title="海產"
                    >
                      <div className="filterImgBg ">
                        <div className="">翠玉瓜腰果炒帶子</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu8.jpg")}
                        alt="翠玉瓜腰果炒帶子"
                      />
                    </a>
                  </Col>
                </Row>
              </div>

              <div id="Fish" className="menuTypeRow">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu9.jpg")}
                      data-sub-html="蒜蓉牛油焗斑塊、川椒欖角蒸大魚頭、鹹酸菜涼瓜炆海斑"
                      title="魚類"
                    >
                      <div className="filterImgBg ">
                        <div>蒜蓉牛油焗斑塊</div> <div className="">｜</div>
                        <div className="">
                          川椒欖角蒸大魚頭{" "}
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                        <div className="">｜</div>
                        <div className="">鹹酸菜涼瓜炆海斑</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu9.jpg")}
                        alt="蒜蓉牛油焗斑塊"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">魚類</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                豉油皇香煎魚雲
                              </div>
                              <div className="DishTitleEng">
                                Fried Fish-head in Soya Sauce
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">薑蔥魚雲煲</div>
                              <div className="DishTitleEng">
                                Fried Fish-head with Ginger & Spring Onion in
                                Casserole
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鹹菜胡椒浸魚雲
                                <span>
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Marinated Fish-head with Preserved Vegetable &
                                Pepper
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蒜蓉牛油焗斑塊
                              </div>
                              <div className="DishTitleEng">
                                Baked Sliced Garoupa with Butter & Garlic
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                芝士牛油焗斑塊
                              </div>
                              <div className="DishTitleEng">
                                Braised Sliced Garoupa with Butter & Cheese
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">粟米燴斑塊</div>
                              <div className="DishTitleEng">
                                Braised Sliced Garoupa with Sweet Com
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                川椒欖角蒸大魚頭{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Steamed Fish-head with Olive & Chili
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                豆腐/涼瓜斑腩煲
                              </div>
                              <div className="DishTitleEng">
                                Stewed Garoupa with Bean Curd / Bitter Melon in
                                Casserole
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鹹酸菜涼瓜炆海斑
                              </div>
                              <div className="DishTitleEng">
                                Braised Garoupa with Vegetable
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>

              <div id="Pork" className="menuTypeRow">
                <Row>
                  <Col lg={7} md={12} className="p-0 order-lg-1 order-2">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">豬肉</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                特級話梅炆豬手{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Braised Pork Knuckle in Plum Sauce
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮鳳梨咕嚕肉（無骨）
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Sweet & Sour Pork with Fresh Pineapple
                                (Boneless)
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮鳳梨生炒骨
                              </div>
                              <div className="DishTitleEng">
                                Sweet & Sour Pork-ribs with Fresh Pineapple
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                金牌沙拉上肉豬扒
                              </div>
                              <div className="DishTitleEng">
                                Fried Pork Chop with Tartar Sauce & Ok Sauce
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                啫啫蝦醬時菜排骨煲{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Spare-rib & Vegetable with Shrimp-paste in
                                Casserole
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                煎上肉豬扒（豉油皇/椒鹽/鎮江醋）
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Pan-fried Pork Chop in Soya Sauce / Salty &
                                Pepper / Black Vinegar
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                惠州梅菜/鮮魷蒸肉餅（鮮肉）
                              </div>
                              <div className="DishTitleEng">
                                Steamed Minced Pork with Preserved Vegetable /
                                Cuttlefish
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={12}
                    role="group"
                    className="imageContainer order-1 order-lg-2"
                  >
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu10.jpg")}
                      data-sub-html="鮮鳳梨咕嚕肉（無骨）、豉油皇煎豬扒"
                      title="豬肉"
                    >
                      <div className="filterImgBg ">
                        <div className="">鮮鳳梨咕嚕肉（無骨）</div>{" "}
                        <div className="">｜</div>
                        <div className="">
                          豉油皇煎豬扒 <span className="bgWithReIcon"></span>{" "}
                          <span className="bgWithReIcon"></span>
                        </div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu10.jpg")}
                        alt="鮮鳳梨咕嚕肉（無骨）"
                      />
                    </a>
                  </Col>
                </Row>
              </div>

              <div id="Chicken" className="menuTypeRow">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu11.jpg")}
                      data-sub-html="招牌秘製沙薑雞、四川香辣雞煲"
                      title="雞肉"
                    >
                      <div className="filterImgBg ">
                        <div>招牌秘製沙薑雞</div>
                        <div>｜</div>
                        <div>四川香辣雞煲</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu11.jpg")}
                        alt="四川香辣雞煲"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">雞肉</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                大澳脆香鮮雞翼{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Chicken Wing in Shrimp Paste
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                招牌秘製沙薑雞{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Marinated Chicken with Ginger Sauce
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">薑蔥霸王雞</div>
                              <div className="DishTitleEng">
                                Braised Chicken with Spring Onion & Ginger
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">菜膽上湯雞</div>
                              <div className="DishTitleEng">
                                Braised Chicken with Seasonal Vegetable
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                海蜇手撕雞（製作需時）
                              </div>
                              <div className="DishTitleEng">
                                Shredded Chicken with Jellyfish
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                四川香辣雞煲{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                  <img
                                    src={require("../assets/image/chili18.jpg")}
                                    className="chiliIcon"
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Sichuan Style Spicy Chicken Hot Pot
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                脆皮蝦醬燒雞（歡迎預訂）
                              </div>
                              <div className="DishTitleEng">
                                Roasted Crispy Chicken in Shrimp Paste (Welcome
                                to Reserve)
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蒜蓉牛油焗雞{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Chicken with Butter & Garlic
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                蟲草花雲耳紅棗蒸滑雞
                              </div>
                              <div className="DishTitleEng">
                                Steamed Chicken with Dry Cordyceps Flower /
                                Fungus & Red Dates
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>

              <div id="Vegelable" className="menuTypeRow">
                <Row>
                  <Col lg={7} md={12} className="p-0 order-lg-1 order-2">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">時蔬</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                金菇羅漢上素{" "}
                                <span>
                                  <img
                                    src={require("../assets/image/VegieIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Braise Vegetable with Enoki Mushroom
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                梅菜蒸茄子/勝瓜/菜心
                                <span>
                                  <img
                                    src={require("../assets/image/VegieIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Steamed Eggplant / Sponge Gourd / Choy Sum with
                                Preserved Vegetable
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮茄雲耳炒翠瓜
                                <span>
                                  <img
                                    src={require("../assets/image/VegieIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Stir-fried Chinese Zucchini with Tomato & Fungus{" "}
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鮮百合銀杏浸時蔬
                                <span>
                                  <img
                                    src={require("../assets/image/VegieIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Poached Seasonal Greens with Fresh Lily Bulbs &
                                Ginkgo{" "}
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                上湯鮮腐竹雲耳浸時蔬
                                <span>
                                  <img
                                    src={require("../assets/image/VegieIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Marinated Vegetable with Bean Curd Sheet &
                                Fungus in Superior Soup{" "}
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                麵醬欖角浸唐生菜
                              </div>
                              <div className="DishTitleEng">
                                Marinated Lettuce with Olive{" "}
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                卡邦尼汁雜菌西蘭花
                              </div>
                              <div className="DishTitleEng">
                                Braised Broccoli & Mushroom with Carbonara
                                Cheese{" "}
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                上湯蝦乾浸時蔬
                              </div>
                              <div className="DishTitleEng">
                                Marinated Vegetable with Dried Shrimp in
                                Superior Soup
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                上湯雲耳浸時蔬
                              </div>
                              <div className="DishTitleEng">
                                Marinated Vegetable with Fungus in Superior Soup
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                啫啫蝦醬時菜煲
                              </div>
                              <div className="DishTitleEng">
                                Fried Vegetable with Shrimp Paste in Casserole
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">海味雜菜煲</div>
                              <div className="DishTitleEng">
                                Boiled Assorted Vegetable with Dried Seafood
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">蝦乾炒椰菜</div>
                              <div className="DishTitleEng">
                                Fried Cabbage with Dried Shrimp
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                鹹魚茸炒時蔬
                              </div>
                              <div className="DishTitleEng">
                                Stir-fried Vegetable with Salty Fish
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                豉蒜生炒涼瓜片
                              </div>
                              <div className="DishTitleEng">
                                Stir-fried Bitter Melon with Black Bean Sauce
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                  <Col
                    lg={5}
                    md={12}
                    role="group"
                    className="imageContainer order-1 order-lg-2"
                  >
                    <a
                      href=""
                      className="lightgallery-item"
                      data-index="1"
                      data-src={require("../assets/image/menu12.jpg")}
                      data-sub-html="鮮百合銀杏浸時蔬、上湯鮮腐竹雲耳浸時蔬"
                      title="時蔬"
                    >
                      <div className="filterImgBg ">
                        <div className="">
                          鮮百合銀杏浸時蔬
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                        <div>｜</div>
                        <div className="">
                          上湯鮮腐竹雲耳浸時蔬
                          <span className="bgWithReIcon"></span>
                          <span className="bgWithReIcon"></span>
                        </div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu12.jpg")}
                        alt="鮮鳳梨咕嚕肉（無骨）"
                      />
                    </a>
                  </Col>
                </Row>
              </div>

              <div id="Bean" className="menuTypeRow">
                <Row>
                  <Col lg={5} md={12} role="group" className="imageContainer">
                    <a
                      href=""
                      className="lightgallery-item"
                      data-src={require("../assets/image/menu13.jpg")}
                      data-sub-html="潮式百花煎釀豆腐、百花蟹籽蒸釀豆腐"
                      title="豆腐"
                    >
                      <div className="filterImgBg ">
                        <div>潮式百花煎釀豆腐</div>
                        <div>｜</div>
                        <div>百花蟹籽蒸釀豆腐</div>
                      </div>
                      <img
                        className="img-responsive"
                        src={require("../assets/image/menu13.jpg")}
                        alt="四川香辣雞煲"
                      />
                    </a>
                  </Col>
                  <Col lg={7} md={12} className="p-0">
                    <div className="TypeMenuDetail">
                      <Container>
                        <div className="TypeMenuTitle">豆腐•蛋</div>
                        <div className="MenuDishWrapper">
                          <div className="MenuDish">
                            <div className="subTitle">豆腐</div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                紅燒北菇豆腐煲
                              </div>
                              <div className="DishTitleEng">
                                Braised Bean Curd & Mushroom with Oyster Sauce
                                in Casserole{" "}
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                百花蟹籽蒸釀豆腐
                              </div>
                              <div className="DishTitleEng">
                                Steamed Bean Curd Stuffed with Crab-seed &
                                Minced Shrimp{" "}
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                潮式百花煎釀豆腐
                              </div>
                              <div className="DishTitleEng">
                                Deep-fried Bean Curd Stuffed with Minced Shrimp{" "}
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                翠塘蛋白蒸豆腐
                              </div>
                              <div className="DishTitleEng">
                                Steamed Bean Curd with Egg White & Assorted
                                Seafood{" "}
                              </div>
                            </div>
                          </div>
                          <div className="MenuDish">
                            <div className="subTitle">蛋類</div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                瑤柱帶子煎蛋角
                              </div>
                              <div className="DishTitleEng">
                                Pan-fried Scrambled Egg with Scallop & Conpoy{" "}
                              </div>
                            </div>
                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">三色蒸水蛋</div>
                              <div className="DishTitleEng">
                                Steamed Scrambled Preserved Egg & Salty Egg{" "}
                              </div>
                            </div>

                            <div className="DishContentWrapper">
                              <div className="DishTitleChinese">
                                瑤柱肉鬆蒸水蛋
                                <span>
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                  <img
                                    src={require("../assets/image/recommIcon.jpg")}
                                  />
                                </span>
                              </div>
                              <div className="DishTitleEng">
                                Steamed Scrambled Egg with Conpoy & Minced Pork{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="RecommIconWrapper">
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/recommIcon.jpg")}
                              />
                            </span>
                            <span>推介</span>
                            <span>Signature</span>
                          </div>
                          <div>
                            <span>
                              <img
                                src={require("../assets/image/VegieIcon.jpg")}
                              />
                            </span>
                            <span>素菜</span>
                            <span>Veggie</span>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </div>
            </LightGallery>
          </Container>
        </section>
      </div>{" "}
    </>
  );
};

export default MenuDetail;
