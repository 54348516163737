import { Container } from "react-bootstrap";
import IndoorPic from "../components/indoorPic";
import { Helmet } from "react-helmet";
import WhatsappIcon from "../components/whatsappIcon";

const IndoorPage = () => {
  return (
    <>
      {" "}
      <WhatsappIcon />
      <Helmet>
        <title>餐廳舒適環境 - 食得妙</title>
        {/* <meta
          name="description"
          content="食得妙提供舒適環境，適合家庭、朋友及商務聚餐。"
        /> */}
        <meta property="og:title" content="餐廳舒適環境 - 食得妙" />
        {/* <meta
          property="og:description"
          content="食得妙提供舒適環境，適合家庭、朋友及商務聚餐。"
        /> */}
      </Helmet>
      <div id="indoorView" className="sectionAfterHeader">
        <div className="sectionTitleWrapper">
          <div className="leftDec">
            <div>
              <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
            </div>
            <div>
              <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
            </div>
          </div>
          <h2 className="basicCol">餐廳舒適環境</h2>
          <div className="rightDec">
            <div>
              <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
            </div>
            <div>
              <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
            </div>
          </div>
        </div>
        <Container className="">
          <IndoorPic />
        </Container>
      </div>
    </>
  );
};
export default IndoorPage;
