// import env1 from "../assets/image/resEnv1.jpg";
// import env2 from "../assets/image/resEnv2.jpg";
// import env3 from "../assets/image/resEnv3.jpg";
// import env4 from "../assets/image/resEnv4.jpg";
import imagesLoaded from "imagesloaded";
import Masonry from "masonry-layout";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lightgallery-bundle.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import { useEffect } from "react";
import "react-visual-grid/dist/react-visual-grid.css";
import "./indoorPic.scss";
const IndoorPic = () => {
  useEffect(() => {
    // Ensure the DOM element exists
    const container = document.querySelector(".gallery");
    if (container) {
      // Initialize Masonry
      const msnry = new Masonry(container, {
        itemSelector: ".gallery-item",
        percentPosition: true,
      });

      // Use imagesLoaded with Masonry
      imagesLoaded(container).on("progress", function () {
        // Layout Masonry after each image loads
        msnry.layout();
      });
    }
  }, []);
  return (
    <div id="lightBox" className="light-box">
      <LightGallery
        elementClassNames={"gallery"}
        selector="a"
        plugins={[lgThumbnail]}
        download={false}
      >
        <div className="gallery-item height-1">
          <a className="HoverImage" href="" data-src={require('../assets/image/resEnv1.jpg')} data-sub-html="餐廳裝修圖-1">
            <div className="gallerImage">
              <img className="img-responsive" src={require('../assets/image/resEnv1.jpg')} alt="餐廳裝修圖-1" />
            </div>
          </a>
        </div>
        <div className="gallery-item  height-2">
          <a  className="HoverImage"href="" data-src={require('../assets/image/resEnv3.jpg')} data-sub-html="餐廳裝修圖-2">
            <div className="gallerImage">
              <img className="img-responsive" src={require('../assets/image/resEnv3.jpg')} alt="餐廳裝修圖-2" />
            </div>
          </a>
        </div>
        <div className="gallery-item height-2">
          <a  className="HoverImage"href="" data-src={require('../assets/image/resEnv4.jpg')} data-sub-html="餐廳裝修圖-3">
            <div className="gallerImage">
              <img className="img-responsive" src={require('../assets/image/resEnv4.jpg')} alt="餐廳裝修圖-3" />
            </div>
          </a>
        </div>
        <div className="gallery-item height-1">
          <a  className="HoverImage"href="" data-src={require('../assets/image/resEnv2.jpg')} data-sub-html="餐廳裝修圖-4">
            <div className="gallerImage">
              <img className="img-responsive" src={require('../assets/image/resEnv2.jpg')} alt="餐廳裝修圖-4" />
            </div>
          </a>
        </div>
      </LightGallery>
    </div>
  );
};
export default IndoorPic;
