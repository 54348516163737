import { Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./contactus.scss";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import WhatsappIcon from "../components/whatsappIcon";
const ContactUsPage = () => {
  return (
    <>
      <WhatsappIcon />
      <Helmet>
        <title>聯絡資料 - 食得妙</title>
        {/* <meta
          name="description"
          content="食得妙位於香港九龍九龍城福佬村道64號地下，歡迎透過致電+852 2383 6686訂座"
        /> */}
        <meta property="og:title" content="聯絡資料 - 食得妙" />
        {/* <meta
          property="og:description"
          content="食得妙位於香港九龍九龍城福佬村道64號地下，歡迎透過致電+852 2383 6686訂座"
        /> */}
      </Helmet>
      <div id="ContactUs" className="sectionAfterHeader">
        <div className="addressPic">
          <iframe
            aria-label="地圖"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3690.6313439031182!2d114.18777000000001!3d22.329778!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404072b3cf74df5%3A0xf943e196ef3b6a84!2z6aOf5b6X5aaZ!5e0!3m2!1szh-TW!2shk!4v1718355788658!5m2!1szh-TW!2shk"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <Container>
          <div className="sectionTitleWrapper">
            <div className="leftDec">
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
            </div>
            <h2 className="basicCol">聯絡資料</h2>
            <div className="rightDec">
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
            </div>
          </div>
          <Col xs={8}>
            <div className="methodWrapper">
              <div className="phoneWrapper">
                <div className="methodIcon mb-3">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="methodText">訂座熱線：</div>
                <div className="methodText">+852 2383 6686</div>
              </div>
              <div className="vertLine d-none d-md-block">
                <div className="vertical"></div>
              </div>
              <div className="vertLine d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
                <hr />
              </div>
              <div className="AddressWrapper">
                <div className="methodIcon mb-3">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="methodText">地址：</div>
                <div className="methodText">香港九龍九龍城福佬村道64號地下</div>
              </div>
            </div>

            {/* <div className="formWrapper">
              <div className="title">聯繫我們</div>
              <form>
                <div className="inputNormal">
                  <input type="text" name="name" placeholder="姓名" />
                </div>
                <div className="inputNormal">
                  <input type="email" name="email" placeholder="電子郵件" />
                </div>
                <div className="comment">
                  <textarea resize name="comment" placeholder="信息" />
                </div>
                <div className="submitButton">
                  <input type="submit" />
                </div>
              </form>
            </div> */}
          </Col>
        </Container>
      </div>
    </>
  );
};
export default ContactUsPage;
