import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import brandlogo from "../assets/image/navbrand.jpg";
import "../components/header.scss";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import FocusTrap from "focus-trap-react";
import $ from "jquery";
function Header() {
  const [isActive, setIsActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const showCloseModal = () => {
    console.log($(".js-menu-toggle").hasClass("collapsed"));
    if ($(".js-menu-toggle").hasClass("collapsed") == true) {
      setIsActive(true);
      console.log("trapped");
    } else if ($(".js-menu-toggle").hasClass("collapsed") == false) {
      setIsActive(false);
      console.log("not trapped");
    }
    if (expanded === false) {
      setIsActive(false);
      console.log(isActive);
    }
  };
  return (
    <header className="App-header">
      <Navbar expanded={expanded} expand="xl">
        <FocusTrap active={isActive}>
          <Container fluid>
            <Navbar.Brand>
              <Link to="home">
                <img
                  src={require("../assets/image/navbrand.jpg")}
                  alt="brand logo"
                ></img>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="js-menu-toggle"
              onClick={() => {
                showCloseModal();
                setExpanded(expanded ? false : "expanded");
              }}
            />

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="">
                <Link to="home" onClick={() => setExpanded(false)}>
                  首頁
                </Link>
                <Link to="HistoryPage" onClick={() => setExpanded(false)}>
                  餐廳簡介
                </Link>
                <Link to="famDish" onClick={() => setExpanded(false)}>
                  「招牌菜」介紹
                </Link>
                <Link to="indoor" onClick={() => setExpanded(false)}>
                  餐廳舒適環境
                </Link>
                <Link to="menuDetail" onClick={() => setExpanded(false)}>
                  餐牌菜式介紹
                </Link>
                <Link to="Contact" onClick={() => setExpanded(false)}>
                  聯絡資料
                </Link>
                {/* <Link to="/carPark" onClick={() => setExpanded(false)}>
                  {" "}
                  泊車服務
                </Link> */}
              </Nav>{" "}
            </Navbar.Collapse>
          </Container>
        </FocusTrap>
      </Navbar>
    </header>
  );
}

export default Header;
