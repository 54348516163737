import $ from "jquery";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
// import "./index.scss";
import { useEffect } from "react";
import HomePage from "./pages/homePage";
import ContactUsPage from "./pages/contactus";
import FamDish from "./pages/famDish";
import HistoryPage from "./pages/history";
import IndoorPage from "./pages/indoor";
import MenuDetail from "./pages/menuDetail";
import PolicyPage from "./pages/policy";
import TermPage from "./pages/term";
import Header from "./components/header";
import Footer from "./components/footer";
import "./index.scss";
import Layout from "./components/Layout";
import { Redirect } from "react-router";
// import Masonry from "masonry-layout";
function Root() {
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
    const scrollHandler = () => {
      if (window.pageYOffset > $("header").outerHeight()) {
        console.log($("header").outerHeight());
        $("header").removeClass("App-header");
        $("header").addClass("headerWithColor");
      } else if (window.pageYOffset === 0) {
        $("header").removeClass("headerWithColor");
        $("header").addClass("App-header");
      } else {
        $("header").removeClass("headerWithColor");
        $("header").addClass("App-header");
      }
    };

    if (
      (location.pathname !== "/" && location.pathname !== "/home") ||
      location.pathname.includes("/index.html")
    ) {
      $("header").removeClass("App-header");
      $("header").addClass("changePage");
    } else {
      $("header").removeClass("changePage");
      $("header").removeClass("headerWithColor");
      $("header").addClass("App-header");
      window.addEventListener("scroll", scrollHandler);
    }

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };

    // Return effect cleanup function to remove listener
  }, [location]);

  useEffect(() => {
    if (
      location.pathname.includes("/menuDetail") ||
      location.pathname === "/famDish"
    ) {
      document.body.classList.add("bgwithYellow");
    } else {
      document.body.classList.remove("bgwithYellow");
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
}

function App() {
  return (
    <>
      <BrowserRouter>
        <Root />
        <Header />
        <Routes>
          <Route path="*" element={<Navigate to="home" replace></Navigate>} />
          {/* <Route path="/" element={<Layout />}> */}
          <Route index element={<HomePage />}></Route>
          <Route path="home" element={<HomePage />}></Route>
          <Route path="HistoryPage" element={<HistoryPage />}></Route>
          <Route path="famDish" element={<FamDish />}></Route>
          <Route path="indoor" element={<IndoorPage />}></Route>
          <Route path="menuDetail" element={<MenuDetail />}></Route>
          <Route path="Contact" element={<ContactUsPage />}></Route>
          <Route path="terms" element={<TermPage />}></Route>
          <Route path="policies" element={<PolicyPage />}></Route>
          {/* </Route> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
const NotFoundPage = () => (
  <div>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
);
