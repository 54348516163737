import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lightgallery-bundle.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
// import dish1 from "../assets/image/famdish1.jpg";
// import famdish2 from "../assets/image/famish2.jpg";
// import famdish3 from "../assets/image/famdish3.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/keyboard";

import { Keyboard, Navigation, Pagination } from "swiper/modules";
import "./FamDishPic.scss";
const FamDishPic = () => {
  return (
    <>
      <LightGallery
        elementClassNames="gallery-group-swiper"
        download={false}
        selector="a.galleryWrapper"
        plugins={[lgThumbnail]}
        showCloseIcon={true}
      >
        <div className="Dishwrapper">
          <Swiper
            rewind={true}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            breakpoints={{
              319: {
                slidesPerView: 1.5,
                spaceBetween: 2,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 2,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
            }}
            Keyboard={true}
            navigation={true}
            className="mySwiper"
            modules={[Pagination, Navigation, Keyboard]}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {" "}
            <SwiperSlide>
              <div className="controlDivSize">
                <a
                  className="galleryWrapper"
                  href={require("../assets/image/貴妃醬炒蟶子皇.jpg")}
                  data-html="<h4>貴妃醬炒蟶子皇煎米粉底</h4>"
                >
                  <div className="gallerImage">
                    <img
                      alt="貴妃醬炒蟶子皇煎米粉底"
                      src={require("../assets/image/貴妃醬炒蟶子皇.jpg")}
                    />
                  </div>

                  <div className="famdishWord">
                    <div className="basicCol famDishName">貴妃醬炒蟶子皇</div>
                    <div className="forCol ContentWordSize">
                      老板大廚明哥，秘製貴妃醬蟶子皇調製肉味爽甜軟口
                    </div>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="controlDivSize">
                <a
                  className="galleryWrapper"
                  href={require("../assets/image/famdish1.jpg")}
                  data-html="<h4>避風塘百花煎釀三寶</h4>"
                >
                  <div className="gallerImage">
                    <img
                      alt="避風塘百花煎釀三寶"
                      src={require("../assets/image/famdish1.jpg")}
                    />
                  </div>

                  <div className="famdishWord">
                    <div className="basicCol famDishName">
                      避風塘百花煎釀三寶
                    </div>
                    <div className="forCol ContentWordSize">
                      招牌手打蝦膠釀三寶，蝦膠真材實料有彈牙口感。
                    </div>
                  </div>
                </a>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className="controlDivSize">
                <a
                  className="galleryWrapper"
                  href={require("../assets/image/famish2.jpg")}
                  data-html="<h4>鮮鳳梨咕嚕肉</h4>"
                >
                  <div className="gallerImage">
                    <img
                      alt="鮮鳳梨咕嚕肉"
                      src={require("../assets/image/famish2.jpg")}
                    />
                  </div>

                  <div className="famdishWord">
                    <div className="basicCol famDishName">鮮鳳梨咕嚕肉</div>
                    <div className="forCol ContentWordSize">
                      採用新鮮梅頭肉，肉質外脆肉嫩，汁帶鮮鳳梨微酸甜汁味，食落開胃開心
                    </div>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="controlDivSize">
                <a
                  className="galleryWrapper"
                  href={require("../assets/image/famdish3.jpg")}
                  data-html="<h2>大澳脆香蝦醬鮮雞翼</h2>"
                >
                  <div className="gallerImage">
                    <img
                      alt="大澳脆香蝦醬鮮雞翼"
                      src={require("../assets/image/famdish3.jpg")}
                    />
                  </div>

                  <div className="famdishWord">
                    <div className="basicCol famDishName">
                      大澳脆香蝦醬鮮雞翼
                    </div>
                    <div className="forCol ContentWordSize">
                      新鮮雞翼，肉質外脆內軟，肉味帶小蝦醬香味好Juicy
                    </div>
                  </div>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>{" "}
        </div>
      </LightGallery>
    </>
  );
};
export default FamDishPic;
