import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "../components/footer.scss";
// import { Row } from "react-bootstrap";
// import brandlogo from "../assets/image/navbrand.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
// import openRiceIcon from "../assets/image/openriceicon.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer className="App-footer">
      <hr />
      <Container className="footerContainer">
        <div className="footerRow">
          <div className="footerImgWrapper">
            <Link to="HomePage">
              <img src={require("../assets/image/navbrand.jpg")} />
            </Link>
          </div>

          <div>
            <div className="footerAddress basicCol">香港九龍九龍城</div>
            <div className="footerAddress basicCol">福佬村道64號地下</div>
          </div>
          <div>
            <div className="ContactUsWrapper">
              <div className="ContactusIconWrapper">
                <div className="ContactText basicCol">聯繫我們：</div>
                <div className="ContactIcons">
                  <div className="footerIcon">
                    <a
                      href="https://wa.me/85296881233/?text=%E4%BD%A0%E5%A5%BD%EF%BC%8C%E6%88%91%E5%BE%9E+https%3A%2F%2Fwww.wonderfulmeal.com.hk+%E4%B8%8A%E8%AA%8D%E8%AD%98%E8%B2%B4%E5%85%AC%E5%8F%B8%EF%BC%8C%E5%B8%8C%E6%9C%9B%E6%9F%A5%E8%A9%A2%E6%9C%8D%E5%8B%99%E3%80%82"
                      aria-label="開啟WhatsApp"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faWhatsapp} />
                    </a>
                  </div>
                  <div className="footerIcon">
                    <a
                      href="https://www.facebook.com/wonderfulmeals/"
                      aria-label="開啟Facebook"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </div>
                  <div className="footerIcon">
                    <a
                      href="https://s.openrice.com/QrbS0LZy000"
                      aria-label="開啟OpenRice"
                      target="_blank"
                    >
                      <img src={require("../assets/image/openriceicon.png")} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="ContactText basicCol">
                訂座熱線：{" "}
                <a className="basicCol" href="tel:+852 23836686">
                  +852 2383 6686
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="footerBottom">
        <Container>
          <div className="footerBottomRow">
            <div className="footerBottomText basicCol">
              © 2024 食得妙 版權所有
            </div>
            <div className="footerBottomWrapper">
              <div className="footerBottomText ">
                <Link to="terms" className="basicCol">
                  條款及細則
                </Link>
              </div>
              <div className="footerBottomText ">
                <Link to="policies" className="basicCol">
                  私隱政策
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
