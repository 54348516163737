import "bootstrap/dist/css/bootstrap.min.css";
import video1 from "../assets/video/video1.mp4";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lightgallery-bundle.css";
import "lightgallery/css/lg-thumbnail.css";
import { Link, useNavigate } from "react-router-dom";
import SimpleParallax from "simple-parallax-js";
import { HashLink } from "react-router-hash-link";
import IndoorPic from "../components/indoorPic";
import FamDishPic from "../components/famdish";
import "./HomePage.scss";
import { Helmet } from "react-helmet";
import WhatsappIcon from "../components/whatsappIcon";
function HomePage() {
  let navigate = useNavigate();
  const changePageClick = () => {
    let path = `/menuDetail`;
    navigate(path);
  };
  let jump = document.querySelectorAll(".rowGrp");
  jump.forEach((el) => {
    el.addEventListener("click", changePageClick);
  });

  return (
    <>
      <Helmet>
        <title>首頁 - 食得妙</title>
        <meta property="og:title" content="首頁 - 食得妙" />
        {/* <meta
          property="og:description"
          content="食得妙自 1995 年開業至今，一直堅持無味精，以高級食材製作出懷舊菜，適合家庭、朋友及商務聚餐，設有代客泊車服務，免費 Wi-Fi，Openrice 好評如潮。"
        /> */}
        {/* <meta
          name="description"
          content="食得妙自 1995 年開業至今，一直堅持無味精，以高級食材製作出懷舊菜，適合家庭、朋友及商務聚餐，設有代客泊車服務，免費 Wi-Fi，Openrice 好評如潮。"
        /> */}
      </Helmet>
      <div className="videoBanner">
        <div className="videoBannerWord">
          <div>食得妙</div>
          <div>Wonderful Restaurant</div>
          <div>一直堅持無味精 以高級食材製作出懷舊菜</div>
        </div>
        <HashLink smooth to="#brief" className="arrow">
          <FontAwesomeIcon icon={faChevronDown} />
        </HashLink>
        <video
          autoPlay
          muted
          loop
          webkit-playsinline=""
          playsinline=""
          poster={require("../assets/video/cover.jpg")}
        >
          <source src={video1} type="video/mp4" />
        </video>
      </div>
      <section id="brief" className="sectionPadding">
        <Container className="brief-container">
          <div className="layer1">
            <img src={require("../assets/image/Layer_1.jpg")} />
          </div>
          <div className="layer2">
            <img src={require("../assets/image/Layer_2.jpg")} />
          </div>
          <div className="breif-content-wrapper">
            <div className="basicCol titleWordSize">
              食得妙
              <br />
              Wonderful Restaurant
            </div>
            <div className="forCol ContentWordSize ">
              食得妙自 1995 年開業至今，一直堅持無味精，以高級食材製作出懷舊菜，
              <br />
              適合家庭、朋友及商務聚餐，晚市設有代客泊車服務，以及門前有咪錶位，免費
              Wi-Fi，Openrice 好評如潮
            </div>
            <div className="bas-button-wrapper">
              <Link to="/HistoryPage" className="basicCol">
                了解更多
              </Link>
            </div>
          </div>
        </Container>
      </section>
      <SimpleParallax>
        <img src={require("../assets/image/banner2.jpg")} alt="image" />
      </SimpleParallax>
      <section id="famDishesSection" className="sectionPadding">
        <Container>
          <div className="sectionTitleWrapper">
            <div className="leftDec">
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
            </div>
            <h2 className="basicCol">招牌菜</h2>
            <div className="rightDec">
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
            </div>
          </div>

          <FamDishPic />

          <div className="bas-button-wrapper">
            <Link to="/famDish" className="basicCol">
              查看更多
            </Link>
          </div>
        </Container>
      </section>
      <section id="resEnv" className="sectionPadding">
        <Container>
          <div className="sectionTitleWrapper">
            <div className="leftDec">
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
            </div>
            <h2 className="basicCol">餐廳舒適環境</h2>
            <div className="rightDec">
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
            </div>
          </div>
          <IndoorPic />
          <div className="bas-button-wrapper">
            <Link to="/indoor" className="basicCol">
              查看更多
            </Link>
          </div>
        </Container>
      </section>
      <section id="menuRec" className="sectionPadding">
        <Container fluid>
          <div className="sectionTitleWrapper">
            <div className="leftDec">
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
            </div>
            <h2 className="basicCol">餐牌菜式介紹</h2>
            <div className="rightDec">
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
            </div>
          </div>

          <Row>
            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink smooth to="/menuDetail/#rice" className="HoverEffect">
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">
                      老婆仔炒飯（瑤柱，翠瓜粒，帶子粒，蛋白）
                    </div>
                    <img
                      src={require("../assets/image/menu1.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">粉麵飯</div>
                </div>
              </HashLink>
            </Col>
            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink
                smooth
                to="/menuDetail/#sideDish"
                className="HoverEffect"
              >
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">南非一口鮮鮑魚（冷盤）</div>
                    <img
                      src={require("../assets/image/menu2.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">前菜</div>
                </div>
              </HashLink>
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink smooth to="/menuDetail/#soup" className="HoverEffect">
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">
                      私房燉湯（材料新鮮、無味精）
                    </div>
                    <img
                      src={require("../assets/image/menu3.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">湯羹</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink
                smooth
                to="/menuDetail/#Abalone"
                className="HoverEffect"
              >
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">鮑汁厚花膠扣鮑魚</div>
                    <img
                      src={require("../assets/image/menu4.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">鮑參翅肚</div>
                </div>
              </HashLink>
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink smooth to="/menuDetail/#Crab" className="HoverEffect">
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">金蒜蒸蟹飯</div>
                    <img
                      src={require("../assets/image/menu5.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">蟹蝦</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink smooth to="/menuDetail/#Shrimp" className="HoverEffect">
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">避風塘百花煎釀三寶</div>
                    <img
                      src={require("../assets/image/menu6.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">鎮店蝦膠</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink smooth to="/menuDetail/#Oyster" className="HoverEffect">
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">招牌秘製貴妃醬炒蟶子皇</div>
                    <img
                      src={require("../assets/image/menu7.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">蟶子、桶蠔</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink
                smooth
                to="/menuDetail/#Seafood"
                className="HoverEffect"
              >
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">翠玉瓜腰果炒帶子</div>
                    <img
                      src={require("../assets/image/menu8.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">海產</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink smooth to="/menuDetail/#Fish" className="HoverEffect">
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">蒜蓉牛油焗斑塊</div>
                    <img
                      src={require("../assets/image/menu9.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">魚類</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink smooth to="/menuDetail/#Pork" className="HoverEffect">
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">鮮鳳梨咕嚕肉（無骨）</div>
                    <img
                      src={require("../assets/image/menu10.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">豬肉</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink
                smooth
                to="/menuDetail/#Chicken"
                className="HoverEffect"
              >
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">四川香辣雞煲</div>
                    <img
                      src={require("../assets/image/menu11.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">雞肉</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink
                smooth
                to="/menuDetail/#Vegelable"
                className="HoverEffect"
              >
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">鮮百合銀杏浸時蔬</div>
                    <img
                      src={require("../assets/image/menu12.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">時蔬</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink smooth to="/menuDetail/#Bean" className="HoverEffect">
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">潮式百花煎釀豆腐</div>
                    <img
                      src={require("../assets/image/menu13.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">魚類</div>
                </div>
              </HashLink>{" "}
            </Col>

            <Col lg={3} md={4} sm={6} className="rowGrp">
              <HashLink
                smooth
                to="/menuDetail/#Classic"
                className="HoverEffect"
              >
                {" "}
                <div className="menuWrapper">
                  <div className="menuImgWrapper">
                    <div className="imgDishTitle">鮑汁蝦籽扒柚皮（兩件）</div>
                    <img
                      src={require("../assets/image/menu14.jpg")}
                      alt="菜單"
                    />
                  </div>
                  <div className="menuTitle">大廚明哥懷舊菜</div>
                </div>
              </HashLink>{" "}
            </Col>
          </Row>
        </Container>
      </section>
      <section id="chef" className="sectionPadding">
        <Container>
          <div className="sectionTitleWrapper">
            <div className="leftDec">
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
            </div>
            <h2 className="basicCol">大廚介紹</h2>
            <div className="rightDec">
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
            </div>
          </div>
          <Row>
            <Col md={5}>
              <div className="chefImg">
                <img src={require("../assets/image/chef.jpg")} alt="廚師" />
              </div>
            </Col>

            <Col md={7}>
              <div className="chefInfoContentWrapper">
                <div className="basicCol chefTitle">明哥 (大廚)</div>
                <hr />
                <div className="chefText">40年主廚經驗</div>
                <hr />
                <div className="chefText">中環鏞記師父出身</div>
                <hr />
                <div className="chefText">曾任招牌老店主廚</div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="map" className="sectionPadding">
        <Container>
          <Row>
            <Col sm={12} lg={4}>
              <div className="AddressWrapper">
                <div className="AddressTextWrapper">
                  <div>食得妙</div>
                  <div>Wonderful Restaurant</div>
                  <div>香港九龍九龍城福佬村道64號地下</div>
                </div>
                <div className="ContactTextWrapper">
                  <div>訂座熱線：</div>
                  <div>
                    <a href="tel:+85223836686">+852 2383 6686</a>
                  </div>
                  <div>公司網址：</div>
                  <div>
                    <a href="http://www.wonderfulmeal.com.hk">
                      http://www.wonderfulmeal.com.hk
                    </a>
                  </div>
                  <div>Openrice：</div>
                  <div>
                    <a href="https://s.openrice.com/QrbS0LZy000">
                      https://s.openrice.com/QrbS0LZy000
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} lg={8}>
              <div className="addressPic">
                <iframe
                  aria-label="地圖"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3690.6313439031182!2d114.18777000000001!3d22.329778!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404072b3cf74df5%3A0xf943e196ef3b6a84!2z6aOf5b6X5aaZ!5e0!3m2!1szh-TW!2shk!4v1718355788658!5m2!1szh-TW!2shk"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <WhatsappIcon />
    </>
  );
}

export default HomePage;
