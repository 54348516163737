import { Container } from "react-bootstrap";
import "./policandterm.scss";
import { Helmet } from "react-helmet";
import WhatsappIcon from "../components/whatsappIcon";
const PolicyPage = () => {
  return (
    <>
      {" "}
      <WhatsappIcon />
      <Helmet>
        <title>私隱政策 - 食得妙</title>
        {/* <meta name="description" content="食得妙網頁的私隱政策" /> */}
        <meta property="og:title" content="私隱政策 - 食得妙" />
        {/* <meta property="og:description" content="食得妙網頁的私隱政策" /> */}
      </Helmet>
      <Container className="policy">
        <article className="sectionPadding">
          <h2 className="text-center basicCol">私隱政策</h2>
          <p className="termText">
            非常歡迎您光臨「食得妙網站」
            （以下簡稱本網站），為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
          </p>
          <p className="subtitle">一、 隱私權保護政策的適用範圍</p>
          <p className="termText">
            隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。
          </p>
          <p className="termText">
            隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。
          </p>
          <p className="subtitle">二、 個人資料的蒐集、處理及利用方式</p>
          <p className="termText">
            當您蒞臨本網站或參與本網站活動時，我們將視活動性質請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料。
          </p>
          <p className="termText">
            本網站蒐集足以識別使用者身分的個人資料、消費與交易資料，或日後經您同意而提供之其他個人資料，都僅供本網站於其內部、依照蒐集之目的進行處理和利用、或為完成提供服務或履行合約義務之必要、或依照相關法令規定或有權政府機關之命令或要求，否則
            本網站不會將足以識別使用者身分的個人資料提供給第三人（包括境內及境外）、或移作蒐集目的以外之使用。
          </p>
          <p className="subtitle">三、 資料之保護</p>
          <p className="termText">
            本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。{" "}
          </p>
          <p className="termText">
            如因業務需要有必要委託本網站相關單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。{" "}
          </p>
          <p className="subtitle">四、 網站對外的相關連結</p>
          <p className="termText">
            本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
          </p>
          <p className="subtitle">五、 與第三人共用個人資料之政策</p>
          <p className="termText">
            本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
            前項但書之情形包括不限於：{" "}
          </p>
          <p className="termText">經由您同意。</p>
          <p className="termText">法律明文規定。</p>
          <p className="termText">為維護國家安全或增進公共利益。</p>
          <p className="termText">為免除您生命、身體、自由或財產上之危險。</p>
          <p className="termText">
            當您在本網站的行為，違反本網站的服務條款或可能損害或妨礙本網站權益或導致任何人遭受損害時，經本網站揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
          </p>
          <p className="termText">有利於您的權益。</p>
          <p className="termText">
            本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
          </p>
        </article>
      </Container>
    </>
  );
};
export default PolicyPage;
