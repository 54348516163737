import { Col, Container, Row } from "react-bootstrap";

import "./history.scss";
import { Helmet } from "react-helmet";
import WhatsappIcon from "../components/whatsappIcon";
const HistoryPage = () => {
  return (
    <>
      {" "}
      <WhatsappIcon />
      <Helmet>
        <title>餐廳簡介 - 食得妙</title>
        {/* <meta
          name="description"
          content="適合家庭、朋友及商務聚餐，晚市設有代客泊車服務，以及門前有咪錶位，免費
              Wi-Fi，Openrice 好評如潮。"
        /> */}
        <meta property="og:title" content="餐廳歷史及簡介 - 食得妙" />
        {/* <meta
          property="og:description"
          content="適合家庭、朋友及商務聚餐，晚市設有代客泊車服務，以及門前有咪錶位，免費
              Wi-Fi，Openrice 好評如潮"
        /> */}
      </Helmet>
      <div id="History" className="sectionAfterHeader">
        <div className="sectionTitleWrapper">
          <div className="leftDec">
            <div>
              <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
            </div>
            <div>
              <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
            </div>
          </div>
          <h2 className="basicCol">餐廳歷史及簡介</h2>
          <div className="rightDec">
            <div>
              <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
            </div>
            <div>
              <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
            </div>
          </div>
        </div>
        <Container className="">
          <Row>
            <div className="HistoryContentWrapper">
              <div className="brandLogoWrapper">
                <img
                  src={require("../assets/image/navbrand.jpg")}
                  alt="brand logo"
                ></img>
              </div>
              <div className="historyContent">
                食得妙自 1995
                年開業至今，一直堅持無味精，以高級食材製作出懷舊菜，
                適合家庭、朋友及商務聚餐，晚市設有代客泊車服務，以及門前有咪錶位，免費
                Wi-Fi，Openrice 好評如潮
              </div>
            </div>
            <div className="historyImage">
              <img src={require("../assets/image/resEnv2.jpg")} />
            </div>
          </Row>
        </Container>
      </div>
      <section id="chef" className="sectionPadding">
        <Container>
          <div className="sectionTitleWrapper">
            <div className="leftDec">
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
            </div>
            <h2 className="basicCol">大廚介紹</h2>
            <div className="rightDec">
              <div>
                <img src={require("../assets/image/famdec2.jpg")} alt="decor" />
              </div>
              <div>
                <img src={require("../assets/image/famdec1.jpg")} alt="decor" />
              </div>
            </div>
          </div>
          <Row>
            <Col md={5}>
              <div className="chefImg">
                <img src={require("../assets/image/chef.jpg")} alt="廚師" />
              </div>
            </Col>

            <Col md={7}>
              <div className="chefInfoContentWrapper">
                <div className="basicCol chefTitle">明哥 (大廚)</div>
                <hr />
                <div className="chefText">40年主廚經驗</div>
                <hr />
                <div className="chefText">中環鏞記師父出身</div>
                <hr />
                <div className="chefText">曾任招牌老店主廚</div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HistoryPage;
