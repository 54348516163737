import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./whatsappIcon.scss";
const WhatsappIcon = () => {
  return (
    <>
      <a
        className="bottomWhatsappIcon"
        href="https://wa.me/85296881233/?text=%E4%BD%A0%E5%A5%BD%EF%BC%8C%E6%88%91%E5%BE%9E+https%3A%2F%2Fwww.wonderfulmeal.com.hk+%E4%B8%8A%E8%AA%8D%E8%AD%98%E8%B2%B4%E5%85%AC%E5%8F%B8%EF%BC%8C%E5%B8%8C%E6%9C%9B%E6%9F%A5%E8%A9%A2%E6%9C%8D%E5%8B%99%E3%80%82"
        aria-label="開啟WhatsApp"
        target="_blank"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
    </>
  );
};
export default WhatsappIcon;
