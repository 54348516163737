import { Container } from "react-bootstrap";
import "./policandterm.scss";
import { Helmet } from "react-helmet";
import WhatsappIcon from "../components/whatsappIcon";
const TermPage = () => {
  return (
    <>
      <WhatsappIcon />
      <Helmet>
        <title>網站條款及細則 - 食得妙</title>
        {/* <meta name="description" content="食得妙的網站條款及細則" /> */}
        <meta property="og:title" content="網站條款及細則 - 食得妙" />
        {/* <meta property="og:description" content="食得妙的網站條款及細則" /> */}
      </Helmet>
      <Container className="term">
        <article className="sectionPadding">
          <h2 className="text-center basicCol">網站條款及細則</h2>
          <p className="termText">
            謝謝閣下瀏覽本網站。請務必仔細閱讀本文件中包含的條款及細則，因為任何使用本網站意即表示閣下已接受在此列出的條款及細則。
            本網站文中凡是提及"我們"、"我們的"、"食得妙"等，泛指食得妙。本網站文中凡是提及〝閣下〞，泛指接觸及╱或使用本網站之任何人士。
          </p>
          <p className="subtitle">網站私隱告示</p>
          <p className="termText">
            所有個人資訊或資料傳輸至本網站均受制於食得妙對私隱和個人資料保護的政策載列於本網站之私隱告示。
          </p>
          <p className="subtitle">資訊之正確性、完整性和及時性</p>
          <p className="termText">
            我們不會對網站內之正確性及完整性資訊負責。所有對於本網站材料的信賴應自行承擔其風險。
            閣下同意負責隨時注意本網站中所有資料與訊息的變更。
          </p>
          <p className="subtitle">傳輸</p>
          <p className="termText">
            所有閣下經由電子郵件或以其他方式傳輸到本網站的非個人通訊或材料，包括所有資料、問題、評論、建議或其他類似內容，均會被視為非機密與非私人化的內容。閣下傳輸或張貼於本網站的所有內容均成為食得妙的財產，且可將其用作任何用途，包括但不限於複製、披露、傳輸、發行、播放及張貼。此外，閣下任何傳輸至本網站的任何構思、美術作品、創意、啟發、建議或概念，食得妙可自由使用於各種用途(包括但不限於產品的開發、生產、宣傳和營銷)。以上所有相關使用，食得妙對於提供資訊者皆無償付責任。當提供資訊的同時亦即表示閣下保證所提供的資料╱內容為閣下本人所有且不會涉及誹謗，且食得妙亦不會因使用該資訊而侵犯任何第三方的權利或在其他情況下令我們違反任何適用的法律。食得妙亦無義務使用所提交的資訊。{" "}
          </p>
          <p className="subtitle">智識產權</p>
          <p className="termText">
            ® 註冊商標持有人為食得妙產品有限公司。食得妙保留所有權利。
          </p>
          <p className="termText">
            本網站上所有文字、圖像和其他素材的版權、商標與其他智識產權皆屬食得妙的財產或包含於有關擁有人的許可。
          </p>
          <p className="termText">
            閣下獲允許瀏覽本網站、可以列印的方式複製摘錄(引用)、可下載至硬碟或轉寄予其他人士為目的。而唯一需要做的前提是閣下必須在該複製內容上完整保留所有的版權、其他所有權告示與上面出現的商標告示。不可將網站中任何部分的複製內容以商業獲利販賣或散佈，亦不可將之修改或加入其他作品、刊物或網站。{" "}
          </p>
          <p className="termText">
            於本網站出現之商標、標識、字樣和服務標示(統稱"商標")皆屬食得妙所有。本網站沒有任何內容可被視為授予特許或有權使用在本網站出現的任何商標。除本條款及細則中規定以外，亦嚴格禁止閣下使用╱誤用本網站出現的商標或在本網站上的任何其他內容。閣下亦須注意，食得妙將會全力直接在最大的法律程度上執行其智識產權。
          </p>
          <p className="subtitle">保證及免責聲明</p>
          <p className="termText">使用本網站請自行承擔相關風險。 </p>
          <p className="subtitle">保證</p>
          <p className="termText">
            本網站提供給閣下一個"現況"及"現有"的基礎上，據此食得妙不提供任何形式的保證，不論其為明示、默示、法定或以其他形式(包括默示保證其商業適售性、滿意的品質及適用性於特定用途)，包括保證或陳述網站內之資料將為完整、準確、可信賴、及時、不侵犯第三方權利、接觸本網站將為暢通無阻或網站內部沒有錯誤和病毒、本網站將為安全、任何從本網站獲取食得妙之建議或意見均為準確或可信賴。任何陳述或保證其相應地明確否認。
          </p>
          <p className="subtitle">法律責任</p>
          <p className="termText">
            無論什麼原因和因閣下接觸、使用、無法使用本網站、網站內容變更或因本網站提供之連結而接觸任何其他網站，或適用法律容許的範圍內或因閣下所發送予我們的電子郵件信息導致我們採取或未有採取的任何行動，不論其為直接、偶然、必然、間接、特別或懲罰性的損害賠償、費用、損失或責任，食得妙及╱或其他參與創作、製作或代表我們提供本網站之人士概不承擔任何法律責任或義務。
          </p>
          <p className="termText">
            此外，食得妙沒有任何責任或義務因閣下於本網站之使用、接觸或下載任何材料而可能令閣下之電腦設備或其他財產感染病毒所遭受的任何損失。若閣下選擇從本網站下載材料，應自行承擔風險。
          </p>
        </article>
      </Container>
    </>
  );
};
export default TermPage;
